/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import '~firebaseui/dist/firebaseui.css';

html,
body {
  height: 100%;
}
body {
  //margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  //padding-top: 12rem;
}
.main-container {
  height: 100%;
  overflow: hidden;
}
.outlet-container{
  //padding-top: 10px;
  height: 100%;
  overflow:auto;
}
body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background: url("/assets/img/bg-main-large.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
}

header.masthead h1 {
  font-size: 2rem;
}
@media (min-height: 500px) {
  header.masthead {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}
@media (min-height: 850px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}



.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background: url("/assets/img/bg-main-large.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}


.btn-teal{
  color: #fff;
  background-color:#19B6BE !important;
  border-color:#19B6BE !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//swagger for smaller screens
.api-background .container .swagger-container .swagger-ui .opblock .opblock-summary-path {
  /* flex-shrink: 0; */
   max-width: 55%;
  word-break: unset !important;
  display: block !important;
}
.swagger-ui .opblock-tag {
  align-items: center;
  border-bottom: 1px solid rgba(59,65,81,.3);
  cursor: pointer;
  display: block;
  padding: 10px 20px 10px 10px;
  transition: all .2s;
}

.swagger-ui .opblock .opblock-summary-description {
  // color: #3b4151;
  // flex: 1 1 auto;
  // font-family: sans-serif;
  // font-size: 13px;
  // word-break: break-word;
  display:none
}

.swagger-ui select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 15px;
  border: 2px solid #41444e;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  color: #3b4151;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 40px 5px 10px;
}
.swagger-ui .opblock-body select {
  min-width: 40px;
  width: 40px;
}

@media screen and (min-width: 500px) {
//swagger for smaller screens
.swagger-ui .opblock .opblock-summary-path {
  flex-shrink: 0;
  max-width: calc(100% - 110px - 15rem);
}
.swagger-ui .opblock-tag {
  align-items: center;
  border-bottom: 1px solid rgba(59,65,81,.3);
  cursor: pointer;
  display: flex;
  padding: 10px 20px 10px 10px;
  transition: all .2s;
}

.swagger-ui .opblock .opblock-summary-description {
  color: #3b4151;
  flex: 1 1 auto;
  font-family: sans-serif;
  font-size: 13px;
  word-break: break-word;
  display: flex;
}
.swagger-ui select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 20px;
  border: 2px solid #41444e;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  color: #3b4151;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 40px 5px 10px;
}
.swagger-ui .opblock-body select {
  min-width: 180px;
}
}